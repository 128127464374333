import PropTypes from 'prop-types';
import { withStyles, IconButton, Grid } from '@material-ui/core';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Person from '@material-ui/icons/Person';
import Email from '@material-ui/icons/Email';
import Edit from '@material-ui/icons/Edit';
import People from '@material-ui/icons/People';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import BusinessCenter from '@material-ui/icons/Business';
import LockOutlined from '@material-ui/icons/LockOutlined';
import ChevronRight from '@material-ui/icons/ChevronRight';
import More from '@material-ui/icons/More';
import Warning from '@material-ui/icons/Warning';
import Input from '@material-ui/icons/Input';
import LockIcon from '@material-ui/icons/Lock';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ThumbUp from '@material-ui/icons/ThumbUp';
import MeetingRoom from '@material-ui/icons/ThumbUp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FlagIcon from '@material-ui/icons/Flag';
import EventNote from '@material-ui/icons/EventNote';
import { VmsDialogRowSelect, VmsDialogRowLabel } from '../rows';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import moment from 'moment';
import {
  VmsInput,
  VmsLocalizationList,
  VmsDialogRowTextField,
  CompanyDataContext,
  EmailSharedRow,
  useSharedEmail,
} from 'components';
import {
  user_status,
  user_roles,
  sanitizePhoneNumber,
  date_formats,
  basic_roles,
  basic_roles_w_tablet,
} from 'AppSettings';
import Auth from 'modules/Auth';
import { useCallback, useContext } from 'react';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  iconRight: {
    marginRight: theme.spacing(2),
  },
  iconWarning: {
    color: theme.palette.primary.main,
  },
  root: {
    alignContent: 'flex-start',
  },
});

const UserInfoTab = ({
  classes,
  onChange,
  item,
  adminMode,
  onChangePassword,
  onChangePin,
  disableEdit,
  departments,
  lockUrlValid,
  visitorView,
  emailValid,
  phoneValid,
  loginValid,
  filteredRoles,
}) => {
  const { companyData } = useContext(CompanyDataContext);

  const isVisitor = item.role === user_roles.VISITOR;

  const onEmailSharedInfoChecked = useCallback(
    (emailSharedInfo) => {
      onChange('emailShared', emailSharedInfo.emailShared)();
      onChange('emailSharedEnabled', emailSharedInfo.emailSharedEnabled)();
    },
    [onChange]
  );
  const loadingEmailCheck = useSharedEmail(
    item.email,
    item.id,
    onEmailSharedInfoChecked,
    { disabled: !isVisitor }
  );

  const roles = Object.values(
    filteredRoles[0]
      ? filteredRoles[1]
        ? basic_roles_w_tablet
        : basic_roles
      : user_roles
  ).filter((r) => {
    return r !== 'globaladmin';
  });
  const userRoles = {
    key: roles,
    value: roles.map((role) => {
      return i18n.t('role_' + role);
    }),
  };
  const userStatus = {
    key: Object.values(user_status),
    value: Object.values(user_status).map((status) => {
      return i18n.t('status_' + status);
    }),
  };
  const isPhoneRequired = Boolean(!item.email && item.phone);

  // logged user
  const isUserAdmin = Auth.isUserAdmin();

  const handlePhoneChange = (event) => {
    onChange('phone', sanitizePhoneNumber(event.target.value))();
  };

  const handleRoleChange = (event) => {
    const role = event.target.value;
    if (role === user_roles.VISITOR) {
      onChange('department', null)(event);
      onChange('company', '')(event);
    } else {
      onChange('company', companyData.location.name)(event);
    }
    onChange('role', role)(event);
  };

  const handleSharedEmailEnabled = (event) => {
    onChange('emailShared', !item.emailShared)(event);
  };

  return (
    <Grid className={classes.root} container>
      <VmsInput
        rowClass={classes.dialogRow}
        iconLeft={<PersonOutline />}
        textValue={item.name}
        placeholder={i18n.t('invite_form_name')}
        inputLabel={i18n.t('invite_form_name')}
        onChange={onChange('name')}
        inputName={'name'}
        disabled={disableEdit}
        required
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<Email />}
        textValue={item.email}
        placeholder={`${i18n.t('invite_form_email')}${
          isPhoneRequired ? ` (${i18n.t('required_phone')})` : ''
        }`}
        inputLabel={`${i18n.t('invite_form_email')}${
          isPhoneRequired ? ` (${i18n.t('required_phone')})` : ''
        }`}
        onChange={onChange('email')}
        inputName={'email'}
        disabled={disableEdit}
        required={!isPhoneRequired}
        inputError={!emailValid}
      />
      {isVisitor && (
        <EmailSharedRow
          dialogRowClass={classes.dialogRow2}
          emailShared={item.emailShared}
          loadingEmailCheck={loadingEmailCheck}
          onEmailSharedClick={handleSharedEmailEnabled}
          disabled={
            disableEdit ||
            !item.emailSharedEnabled ||
            !emailValid ||
            !item.email
          }
          checkboxDataCy="user-checkbox-email-shared"
        />
      )}
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<PhoneIphone />}
        textValue={item.phone}
        placeholder={`${i18n.t('invite_form_phone')}${
          !isPhoneRequired ? ` (${i18n.t('required_email')})` : ''
        }`}
        inputLabel={`${i18n.t('invite_form_phone')}${
          !isPhoneRequired ? ` (${i18n.t('required_email')})` : ''
        }`}
        onChange={handlePhoneChange}
        inputName={'phone'}
        disabled={disableEdit}
        required={isPhoneRequired}
        inputError={!phoneValid}
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<Person />}
        textValue={item.login}
        placeholder={i18n.t('invite_form_login')}
        inputLabel={i18n.t('invite_form_login')}
        onChange={onChange('login')}
        inputName={'login'}
        disabled={disableEdit}
        inputError={!loginValid}
      />
      <VmsInput
        disabled={disableEdit || item.role !== user_roles.VISITOR}
        rowClass={classes.dialogRow2}
        iconLeft={<BusinessCenter />}
        textValue={item.company}
        placeholder={i18n.t('invite_form_company')}
        inputLabel={i18n.t('invite_form_company')}
        onChange={onChange('company')}
        inputName={'company'}
      />
      {item.role !== user_roles.VISITOR && !visitorView && (
        <VmsDialogRowSelect
          rowClass={classes.dialogRow2}
          iconLeft={<More />}
          placeholder={i18n.t('users_dialog_department')}
          onChange={onChange('department')}
          inputName={'department'}
          selectTypes={departments}
          selectedType={item.department || ''}
          disabled={disableEdit}
        />
      )}
      {(item.role === user_roles.MEETING_ROOM ||
        item.role === user_roles.PORTER ||
        item.role === user_roles.TURNSTILE) && (
        <VmsInput
          rowClass={classes.dialogRow2}
          iconLeft={<Input />}
          textValue={item.lockUrl}
          placeholder={i18n.t('users_dialog_lockurl')}
          inputLabel={i18n.t('users_dialog_lockurl')}
          onChange={onChange('lockUrl')}
          inputName={'lockUrl'}
          inputError={lockUrlValid}
        />
      )}
      {item.id && item.email && (
        <VmsInput
          rowClass={classes.dialogRow2}
          onClick={onChangePassword}
          iconLeft={<LockOutlined />}
          textValue={''}
          placeholder={i18n.t('users_dialog_password')}
          inputLabel={i18n.t('users_dialog_password')}
          iconRight={<ChevronRight className={classes.iconRight} />}
          disabled={true}
          onChange={onChange('password')}
          inputName={'password'}
        />
      )}
      {item.id && !item.email && item.phone && (
        <VmsInput
          rowClass={classes.dialogRow2}
          onClick={onChangePin}
          iconLeft={<LockOutlined />}
          placeholder={i18n.t('users_dialog_pin')}
          inputLabel={i18n.t('users_dialog_pin')}
          iconRight={<ChevronRight className={classes.iconRight} />}
          disabled={true}
          onChange={onChange('pin')}
          inputName={'pin'}
        />
      )}
      {adminMode && (
        <VmsDialogRowSelect
          rowClass={classes.dialogRow2}
          iconLeft={<People />}
          placeholder={i18n.t('users_dialog_role')}
          onChange={handleRoleChange}
          inputName={'role'}
          selectTypes={userRoles}
          selectedType={item.role}
          disabled={disableEdit || item.emailShared}
          selectDataCy="user-select-role"
          itemDataCy="user-list-item-role"
          required
        />
      )}
      {adminMode && (
        <VmsDialogRowSelect
          rowClass={classes.dialogRow2}
          iconLeft={<Edit />}
          placeholder={i18n.t('users_dialog_status')}
          onChange={onChange('status')}
          inputName={'status'}
          selectTypes={userStatus}
          selectedType={item.status}
          disabled={disableEdit}
          selectDataCy="user-select-status"
          itemDataCy="user-list-item-status"
          required
        />
      )}
      <VmsDialogRowTextField
        rowClass={classes.dialogRowNote}
        iconLeft={<EventNote />}
        textValue={item.note}
        inputName="note"
        placeholder={i18n.t('user_form_note')}
        inputLabel={i18n.t('user_form_note')}
        onChange={onChange('note')}
        disabled={disableEdit}
        multiline={true}
        rows={5}
        maxLength={512}
      />
      {adminMode && item.status === user_status.CLOSED && (
        <VmsDialogRowLabel
          rowClass={classes.dialogRow2}
          iconLeft={<Warning className={classes.iconWarning} />}
          textValue={i18n.t('users_dialog_will_be_deleted')}
          disabled={disableEdit}
        />
      )}
      {adminMode && (
        <VmsInput
          rowClass={classes.dialogRow2}
          iconLeft={<ThumbUp />}
          iconRight={
            <IconButton
              data-cy="user-checkbox-agree-terms"
              disabled={disableEdit}
              onClick={onChange('agreeTerms', !item.agreeTerms)}
            >
              {item.agreeTerms ? (
                <CheckBox color="primary" />
              ) : (
                <CheckBoxOutlineBlank />
              )}
            </IconButton>
          }
          textValue={i18n.t('terms_of_use')}
          inputLabel={i18n.t('terms_of_use')}
          disabled={disableEdit}
        />
      )}
      {adminMode && (
        <VmsInput
          rowClass={classes.dialogRow2}
          iconLeft={<LockIcon />}
          iconRight={
            <IconButton
              data-cy="user-checkbox-password-never-expires"
              disabled={disableEdit}
              onClick={onChange(
                'passwordNeverExpires',
                !item.passwordNeverExpires
              )}
            >
              {item.passwordNeverExpires ? (
                <CheckBox color="primary" />
              ) : (
                <CheckBoxOutlineBlank />
              )}
            </IconButton>
          }
          textValue={i18n.t('password_never_expires')}
          inputLabel={i18n.t('password_never_expires')}
          disabled={disableEdit}
        />
      )}
      {adminMode &&
        isUserAdmin &&
        (item.role === user_roles.EMPLOYEE ||
          item.role === user_roles.ADMIN) && (
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<MeetingRoom />}
            iconRight={
              <IconButton
                data-cy="user-checkbox-allow-meeting-room-booking"
                disabled={disableEdit}
                onClick={onChange(
                  'allowMeetingRoomBooking',
                  !item.allowMeetingRoomBooking
                )}
              >
                {item.allowMeetingRoomBooking ? (
                  <CheckBox color="primary" />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
              </IconButton>
            }
            textValue={i18n.t('meeting_room_booking')}
            inputLabel={i18n.t('meeting_room_booking')}
            disabled={disableEdit}
          />
        )}
      <VmsLocalizationList
        lang={item.language}
        onChange={onChange('language')}
        iconLeft={<FlagIcon />}
        rowClass={classes.dialogRow2}
        justifyContent="flex-start"
        selectDataCy="user-select-language"
        itemDataCy="user-list-item-language"
        required
      />
      {adminMode && isUserAdmin && (
        <VmsInput
          rowClass={classes.dialogRow2}
          iconLeft={<ExitToAppIcon />}
          textValue={
            item.lastLogin
              ? moment(item.lastLogin).format(date_formats.full_date_long)
              : i18n.t('users_dialog_no_last_login')
          }
          placeholder={i18n.t('users_dialog_last_login')}
          inputLabel={i18n.t('users_dialog_last_login')}
          inputName={'lastLogin'}
          disabled={true}
        />
      )}
    </Grid>
  );
};

UserInfoTab.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    lockUrl: PropTypes.string,
    agreeTerms: PropTypes.bool,
    allowMeetingRoomBooking: PropTypes.bool,
    language: PropTypes.string,
  }).isRequired,
  adminMode: PropTypes.bool,
  onChangePassword: PropTypes.func,
  onChangePin: PropTypes.func,
  departments: PropTypes.object,
  visitorView: PropTypes.bool,
  disableEdit: PropTypes.bool,
  emailValid: PropTypes.bool.isRequired,
  phoneValid: PropTypes.bool.isRequired,
  loginValid: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(UserInfoTab);
