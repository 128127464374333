import PropTypes from 'prop-types';
import { withStyles, Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PersonOutline from '@material-ui/icons/PersonOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import { VmsDialog } from 'components';
import {
  user_roles,
  user_status,
  validatePhoneNumber,
  validateLogin,
} from 'AppSettings';
import Auth from 'modules/Auth';
import * as EmailValidator from 'email-validator';
import { UserDocsTab, UserEventsTab, UserInfoTab } from './settings';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
  tabs: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '100%',
    flexShrink: 0,
  },
  tab: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.2,
  },
  tabIndicator: {
    height: 4,
    backgroundColor: theme.palette.tabIndicator,
  },
  tabContent: {
    flex: 1,
    width: '100%',
    '& .react-swipeable-view-container': {
      height: '100%',
    },
  },
});

const UserDialog = (props) => {
  const {
    classes,
    theme,
    onSave,
    onSend,
    onClose,
    onChange,
    open,
    item,
    adminMode,
    disableEdit,
    departments,
    visitorView,
    userEditState,
    handleChangeTabs,
    handleChangeTabsIndex,
    personal_files,
    handleChangeFileUserData,
    onChangePassword,
    onChangePin,
    loading,
  } = props;

  // Email is not validated to allow email (login) like admin, porter, turnstile
  const phoneIsValid = !validatePhoneNumber(item.phone);
  const emailIsValid = !item.email || EmailValidator.validate(item.email);
  const loginIsValid = item.login ? validateLogin(item.login) : true;

  const saveDisabled2ndPass =
    !phoneIsValid ||
    !emailIsValid ||
    !item.name ||
    disableEdit ||
    (!item.phone && !item.email) ||
    !item.language ||
    !loginIsValid;

  const saveDisabledForMeetingRoom =
    item.role === user_roles.MEETING_ROOM && !item.lockUrl.includes('!');

  // Email to roles meeting room and turnstile should not be available
  const sendDisabledByRole =
    item.role === user_roles.MEETING_ROOM ||
    item.role === user_roles.TURNSTILE ||
    item.role === user_roles.SIGNING_TABLET;

  // logged user
  const isUserAdmin = Auth.isUserAdmin();

  const tabs = [
    <Tab
      data-cy="user-tab-edit"
      key={
        item.id
          ? i18n.t('users_dialog_title_update')
          : i18n.t('users_dialog_title_new')
      }
      className={classes.tab}
      label={
        item.id
          ? i18n.t('users_dialog_title_update')
          : i18n.t('users_dialog_title_new')
      }
    />,
    <Tab
      data-cy="user-tab-documents"
      key={i18n.t('invite_form_tab_docs')}
      className={classes.tab}
      label={i18n.t('invite_form_tab_docs')}
    />,
  ];

  const views = [
    <UserInfoTab
      key={1}
      onChange={onChange}
      item={item}
      adminMode={adminMode}
      onChangePassword={onChangePassword}
      onChangePin={onChangePin}
      disableEdit={disableEdit}
      departments={departments}
      lockUrlValid={saveDisabledForMeetingRoom}
      visitorView={visitorView}
      emailValid={emailIsValid}
      phoneValid={phoneIsValid}
      loginValid={loginIsValid}
      filteredRoles={props.filteredRoles}
    />,
    <UserDocsTab
      key={2}
      item={item}
      onChange={onChange}
      changeFileData={handleChangeFileUserData}
      onDragDropChange={props.handleDragDrop}
      onDragDropFileChange={props.handleDragDropFile}
      disableEdit={disableEdit}
      userEdit={userEditState}
      dragDropState={userEditState.dragDropState}
      dragDropFileState={userEditState.dragDropFileState}
      photo={userEditState.photo}
      personal_files={personal_files}
    />,
  ];

  if (isUserAdmin) {
    tabs.push(
      <Tab
        data-cy="user-tab-events"
        key={i18n.t('user_events')}
        className={classes.tab}
        label={i18n.t('user_events')}
      />
    );
    views.push(<UserEventsTab key={3} id={item.id} />);
  }

  return (
    <VmsDialog
      dialogId="user-dialog"
      open={open}
      xsPaperMode={props.xsPaperMode}
      onClose={onClose}
      closeButtonDataCy="user-button-close"
      dialogHeaderIcon={<PersonOutline />}
      buttonIcon={<CheckCircle className={classes.fabIcon} />}
      buttonText={i18n.t('save')}
      buttonAction={onSave}
      buttonDisabled={saveDisabled2ndPass || saveDisabledForMeetingRoom}
      buttonDataCy="user-button-save"
      buttonSendAction={adminMode ? onSend : null}
      buttonSendDisabled={
        saveDisabled2ndPass ||
        item.status !== user_status.PENDING ||
        sendDisabledByRole ||
        !item.email
      }
      buttonSendDataCy="users-button-send"
      loading={loading}
    >
      <Tabs
        value={userEditState.tabs.value}
        onChange={handleChangeTabs}
        variant="fullWidth"
        classes={{ indicator: classes.tabIndicator }}
        className={classes.tabs}
      >
        {tabs}
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={userEditState.tabs.value}
        className={classes.tabContent}
        onChangeIndex={handleChangeTabsIndex}
      >
        {views}
      </SwipeableViews>
    </VmsDialog>
  );
};

UserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleChangeTabs: PropTypes.func.isRequired,
  handleChangeTabsIndex: PropTypes.func.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    lockUrl: PropTypes.string,
    agreeTerms: PropTypes.bool,
    allowMeetingRoomBooking: PropTypes.bool,
    language: PropTypes.string,
  }).isRequired,
  adminMode: PropTypes.bool, // In admin mode we allow to change role and status
  onChangePassword: PropTypes.func,
  onChangePin: PropTypes.func,
  onDragDropChange: PropTypes.func,
  dragDropState: PropTypes.object,
  xsPaperMode: PropTypes.bool, // This is special mode that paper instead of dialog is used on some pages where its better
  departments: PropTypes.object,
  visitorView: PropTypes.bool,
  handleDragDrop: PropTypes.func.isRequired,
  handleDragDropFile: PropTypes.func.isRequired,
  disableEdit: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(UserDialog);
