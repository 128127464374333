import { MoreHoriz } from '@material-ui/icons';
import { routes } from 'AppSettings';
import i18n from 'assets/i18n';
import tableStyles from 'assets/jss/tableStyles';
import classNames from 'classnames';
import { openSnackbar } from 'components';
import { handleOpenConfirmDialog } from 'components/common/dialogs/ConfirmDialog';
import TenantDialog from 'components/settings/tenants/TenantDialog';
import Auth from 'modules/Auth';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import TenantService from 'services/TenantService';

const {
  Grid,
  withStyles,
  Table,
  TableBody,
  TableRow,
  Typography,
  TableCell,
  IconButton,
  MenuItem,
  Menu,
} = require('@material-ui/core');
const {
  createTableHeaderState,
  default: VmsTableHeader,
} = require('components/common/material-ui/VmsTableHeader');
const { Component } = require('react');

const styles = (theme) => ({
  ...tableStyles(theme),
});

class TenantsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenants: [],
      menuAnchorEl: null,
      currentItem: null,
      dialogOpen: false,
      tableHeaderState: createTableHeaderState([]),
      newItem: false,
    };

    this.tableHeader = null;
  }

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  componentDidMount() {
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadData();
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_TENANTS) this.handleNewClick();
  };
  loadData = async () => {
    try {
      const response = await TenantService.getTenants();
      this.setState({ tenants: response });
      this.setState((prevState) => ({
        tableHeaderState: { ...prevState.tableHeaderState, data: response },
      }));
    } catch (e) {
      openSnackbar(e.message);
      console.log(e);
    }
  };

  handleMenuOpen = (event, item) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      currentItem: item,
    });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null, currentItem: {} });
  };
  handleChangeTableHeader = (tableHeaderState) => {
    this.setState({ tableHeaderState: tableHeaderState });
  };

  handleNewClick = () => {
    this.setState({
      dialogOpen: true,
      menuAnchorEl: null,
      newItem: true,
      currentItem: {
        name: '',
        version: 'BASIC',
        timezone: '',
        language: '',
        copyTenant: '',
      },
    });
  };

  handleUpdateTenant = (event, parName, parValue) => {
    const { currentItem } = this.state;
    const name = event?.target.name ? event.target.name : parName;
    const value = event?.target.value ? event.target.value : parValue;
    currentItem[name] = value;
    this.setState({ currentItem });
  };

  handleSaveTenant = async () => {
    try {
      const { currentItem, tenants } = this.state;
      const newTenant = currentItem;
      newTenant.copyTenant = tenants.find(
        (t) => t.name === newTenant.copyTenant
      )._id;
      await TenantService.createTenant(newTenant);
      openSnackbar(i18n.t('new_tenant_created'));
      this.setState({
        currentItem: null,
        dialogOpen: false,
        newItem: false,
      });
      this.loadData();
    } catch (e) {
      console.log(e);
      openSnackbar(e.message);
    }
  };
  handleDialogOpen = (item) => (event) => {
    this.setState({
      dialogOpen: true,
      menuAnchorEl: null,
      currentItem: item,
    });
  };

  handleDeleteTenant = (item) => async (event) => {
    try {
      await TenantService.deactivateTenant(item._id);
      this.setState({ menuAnchorEl: null, currentItem: {} });
      await this.loadData();
      openSnackbar(i18n.t('tenant_deactivated'));
    } catch (e) {
      console.log(e);
      openSnackbar(e.message);
    }
  };

  handleDeleteTenantOpenDialog = (tenant) => (event) => {
    this.handleMenuClose();
    if (Auth.getUser().tenantId === tenant._id) {
      openSnackbar(i18n.t('cannot_deactivate_your_tenant'));
      return;
    }
    handleOpenConfirmDialog(
      i18n.t('settings_tenant_dialog_remove_title'),
      i18n.t('settings_tenant_dialog_remove_description'),
      this.handleDeleteTenant(tenant)
    );
  };

  handleDialogOnClose = () => {
    this.setState({
      currentItem: null,
      dialogOpen: false,
      newItem: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { tableHeaderState, currentItem, tenants, menuAnchorEl, dialogOpen } =
      this.state;
    const { rowsPerPage, page } = tableHeaderState;
    const visibleItems = tenants.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return (
      <div>
        <Grid container>
          <Grid item className={classes.settingsTableGridContent}>
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                prevPageButtonDataCy="settings-tenants-button-previous-page"
                nextPageButtonDataCy="settings-tenants-button-next-page"
                limitedView={true}
              />
              <TableBody>
                {visibleItems.map((item, idx) => {
                  return (
                    <TableRow key={idx} hover className={classes.tableRow}>
                      <TableCell
                        padding="checkbox"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                      ></TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(classes.tableCell)}
                        padding="none"
                        onClick={this.handleDialogOpen(item)}
                      >
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.header,
                            classes.tableInfoFont
                          )}
                          noWrap
                        >
                          {item?.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        scope="row"
                        className={classNames(
                          classes.tableCell,
                          classes.tableCellCheckbox
                        )}
                        padding="none"
                      >
                        <IconButton
                          className={classes.tableMenuButton}
                          aria-owns={menuAnchorEl ? 'table-menus' : null}
                          aria-haspopup="true"
                          onClick={(event) => this.handleMenuOpen(event, item)}
                        >
                          <MoreHoriz className={classes.tableIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={this.handleDialogOpen(currentItem)}>
            {i18n.t('menu_show')}
          </MenuItem>
          <MenuItem onClick={this.handleDeleteTenantOpenDialog(currentItem)}>
            {i18n.t('menu_delete')}
          </MenuItem>
        </Menu>
        <TenantDialog
          newItem={!this.state.newItem}
          classes={classes}
          open={dialogOpen}
          onClose={this.handleDialogOnClose}
          item={currentItem}
          onChange={this.handleUpdateTenant}
          onSave={this.handleSaveTenant}
          tenants={tenants.map((tenant) => {
            return { key: tenant.name, value: tenant._id };
          })}
        />
      </div>
    );
  }
}
export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(TenantsPage);
