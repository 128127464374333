import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  MenuItem,
  Grid,
  MenuList,
  withStyles,
  IconButton,
  ClickAwayListener,
  Popover,
  LinearProgress,
  ListItemText,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import debounce from 'debounce';
import { openSnackbar } from '../bars/SnackBar';
import Aux from 'hoc/Auxiliary';
import commonStyles from 'assets/jss/commonStyles';
import { VmsInput } from 'components';
import i18n from '../../../assets/i18n';

const styles = (theme) => ({
  ...commonStyles(theme),
  loading: {
    flexGrow: 1,
    height: 2,
  },
  iconSelect: {
    color: theme.palette.primary6,
    pointerEvents: 'none',
  },
  iconWrapper: {
    marginRight: 4,
  },
  menuItem: {
    margin: 0,
  },
});

class VmsAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      suggestions: [],
      isWaitingForSuggestions: false,
    };
    this.lastNotFound = '';
    this.listRef = createRef();
  }

  handleSuggestionsFetchRequested = (value) => {
    this.setState({
      isWaitingForSuggestions: true,
    });
    this._getSuggestions(value);
  };

  // debounced function so it only gets suggestions every 500ms
  _getSuggestions = debounce((value) => {
    const { suggestionService, filterSuggestionsResponse } = this.props;
    if (!this.props.offline) {
      suggestionService(value === '' ? '.*' : value)
        .then((response) => {
          const suggestions = filterSuggestionsResponse(response);
          if (!suggestions || !suggestions.length) {
            this.lastNotFound = value;
          }
          this.setState({
            suggestions: suggestions,
            isWaitingForSuggestions: false,
          });
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    } else {
      if (value === '.*') {
        this.setState({
          suggestions: this.props.offline,
          isWaitingForSuggestions: false,
        });
      } else {
        const suggestions = this.props.offline.filter((item) =>
          item.toLowerCase().includes(value.toLowerCase())
        );
        if (!suggestions || !suggestions.length) {
          this.lastNotFound = value;
        }
        this.setState({
          suggestions: suggestions,
          isWaitingForSuggestions: false,
        });
      }
    }
  }, 500);

  handleCloseMenu = (event) => {
    this.setState({
      listOpen: false,
    });
  };

  componentDidUpdate(prevProps) {
    const { inputValue } = this.props;
    const { listOpen } = this.state;
    if (
      listOpen &&
      inputValue !== prevProps.inputValue &&
      (!this.lastNotFound || !inputValue.startsWith(this.lastNotFound))
    ) {
      this.handleSuggestionsFetchRequested(inputValue);
    }
  }

  handleOpenMenu = (event) => {
    this.setState({ listOpen: true, suggestions: [] });
    if (this.props.showAllSuggestions) {
      this.handleSuggestionsFetchRequested('.*');
    } else {
      const input = event.target.parentNode.parentNode.previousSibling.value;
      this.handleSuggestionsFetchRequested(input);
    }
  };

  handleChangeInput = (event) => {
    const { inputName, onSuggestionsClearRequested } = this.props;
    this.props.onChange(inputName)(event);
    this.setState({ listOpen: true, suggestions: [] });
    onSuggestionsClearRequested()(event);
  };

  handleSelectedSuggestion = (suggestion) => (event) => {
    const { onSuggestionSelected } = this.props;
    this.setState({ listOpen: false });
    onSuggestionSelected(suggestion)(event);
  };

  render() {
    const {
      classes,
      disabled,
      inputValue,
      placeholder,
      endAdornment,
      inputName,
      suggestionItemValue,
      inputClass,
      customClass,
      disableUnderline,
      inputError,
      itemDataCy,
      disablePop,
    } = this.props;
    const { listOpen, suggestions, isWaitingForSuggestions } = this.state;

    let adornment = endAdornment;
    if (!endAdornment) {
      adornment = (
        <IconButton
          className={classes.iconWrapper}
          onClick={this.handleOpenMenu}
          disabled={disabled}
        >
          <ArrowDropDown className={classes.iconSelect} />
        </IconButton>
      );
    }

    const options = [];
    suggestions.forEach((option) => {
      let value = suggestionItemValue(option);
      if (value) {
        options.push(
          <MenuItem
            data-cy={itemDataCy}
            key={option._id}
            // selected={option === selectedType}
            value={option}
            onClick={this.handleSelectedSuggestion(option)}
          >
            <ListItemText
              className={classes.menuItem}
              primaryTypographyProps={{ noWrap: true }}
            >
              {value}
            </ListItemText>
          </MenuItem>
        );
      }
    });

    return (
      <Aux className={classNames(this.props.className)}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} className={customClass}>
            <VmsInput
              inputName={inputName}
              textValue={inputValue || ''}
              placeholder={placeholder || ''}
              onChange={this.handleChangeInput}
              iconRight={adornment}
              disabled={disabled}
              disableUnderline={disableUnderline}
              inputClass={inputClass}
              autoComplete="off"
              inputError={inputError}
            />
          </Grid>

          <Grid ref={this.listRef} item xs={12}></Grid>

          <Popover
            open={listOpen && disablePop}
            anchorEl={this.listRef.current}
            disableAutoFocus
            disableEnforceFocus
          >
            <Paper
              className={classes.dropDownMenuAutoComplete}
              style={{ width: this.listRef.current?.offsetWidth }}
            >
              <ClickAwayListener onClickAway={this.handleCloseMenu}>
                <MenuList>
                  {isWaitingForSuggestions && (
                    <MenuItem data-cy={itemDataCy}>
                      <LinearProgress className={classes.loading} />
                    </MenuItem>
                  )}
                  {!isWaitingForSuggestions && !suggestions.length && (
                    <MenuItem
                      data-cy={itemDataCy}
                      onClick={this.handleCloseMenu}
                    >
                      {i18n.t('no_suggestions')}
                    </MenuItem>
                  )}
                  {options}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popover>
        </Grid>
      </Aux>
    );
  }
}

VmsAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  endAdornment: PropTypes.node,
  inputName: PropTypes.string.isRequired,
  inputClass: PropTypes.string, // if specified, it will be used as input class
  suggestionService: PropTypes.func.isRequired,
  filterSuggestionsResponse: PropTypes.func.isRequired,
  suggestionItemValue: PropTypes.func.isRequired,
  disableUnderline: PropTypes.bool,
  disabled: PropTypes.bool,
  inputError: PropTypes.bool,
  itemDataCy: PropTypes.string,
  disablePop: PropTypes.bool,
  showAllSuggestions: PropTypes.bool,
};

VmsAutocomplete.defaultProps = {
  disablePop: true,
  showAllSuggestions: true,
};

export default withStyles(styles)(VmsAutocomplete);
