import {
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  withStyles,
} from '@material-ui/core';
import {
  AccessTime,
  ArrowDropDown,
  ArrowDropUp,
  Business,
  Event,
  Flag,
  School,
} from '@material-ui/icons';
import { language_mapping } from 'AppSettings';
import commonStyles from 'assets/jss/commonStyles';
import modalStyle from 'assets/jss/modalStyle';
import { elevation } from 'assets/UISettings';
import {
  VmsDialogFooter,
  VmsDialogHeader,
  VmsDialogRowAutocomplete,
  VmsDialogRowSelectArray,
  VmsInput,
} from 'components/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyles(theme),
  menuFlag: {
    witdh: 30,
    height: 20,
    boxShadow: theme.shadows[3],
  },
  flag: {
    width: 40,
    height: 30,
  },
});

const TenantDialog = (props) => {
  const { classes, onClose, open, item, newItem, onSave, onChange, tenants } =
    props;
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);

  const disabledSave = Boolean(
    item?.name &&
      item?.vesrion !== '' &&
      item?.timezone !== '' &&
      item?.language !== ''
  );
  const handleOpenLanguageMenu = (e) => {
    if (!newItem) setOpenLanguageMenu(e.currentTarget);
  };

  const handleClearTimezone = () => (event) => {};

  const handleSelectTimezone = (timezone) => (event) => {
    console.log('event', event);
    console.log('timezone', timezone);
    onChange(event, 'timezone', timezone);
  };

  const handleChangeTimezone = () => (event) => {
    console.log('zony');
    console.log('event', event);
    onChange(event, 'timezone', event.target.value);
  };

  const handleChangeCopyTenant = (event) => {
    if (event.target.value === 'unselected') {
      onChange(null, 'copyTenant', '');
    } else {
      onChange(event);
    }
  };

  const [t] = useTranslation();
  return (
    <Modal
      className={classes.modal}
      aria-labelledby="Tenant dialog"
      aria-describedby="Dialog showing tenant info"
      open={open}
      onClose={onClose}
    >
      <Paper className={classes.paper} elevation={elevation.light}>
        <VmsDialogHeader
          icon={<Event />}
          title={t('tenant_info')}
          onClose={onClose}
        />
        <VmsInput
          rowClass={classes.dialogRow}
          iconLeft={<Business />}
          textValue={item?.name}
          onChange={onChange}
          inputName="name"
          disabled={newItem}
          placeholder={t('settings_company_location_name')}
          required
        />
        <VmsDialogRowSelectArray
          rowClass={classes.dialogRow2}
          iconLeft={<School />}
          selectTypes={[
            { key: 'BASIC', value: 'BASIC' },
            { key: 'STANDARD', value: 'STANDARD' },
            { key: 'PREMIUM', value: 'PREMIUM' },
          ]}
          selectedType={item?.version}
          mapOfItem={(visitorType) => visitorType.key}
          placeholder={t('settings_company_version')}
          disabled={newItem}
          inputName="version"
          onChange={onChange}
          required
        />
        <VmsDialogRowAutocomplete
          rowClass={classes.dialogRow2}
          iconLeft={<AccessTime />}
          onSuggestionsClearRequested={handleClearTimezone}
          onSuggestionSelected={handleSelectTimezone}
          onChange={handleChangeTimezone}
          filterSuggestionsResponse={(response) => response}
          suggestionItemValue={(suggestion) => suggestion}
          placeholder={t('settings_company_timezone')}
          offline={Intl.supportedValuesOf('timeZone')}
          inputValue={item?.timezone}
          disabled={newItem}
          required
        />
        <>
          <VmsInput
            rowClass={classes.dialogRow2}
            iconLeft={<Flag />}
            iconRight={
              <>
                {item?.language ? (
                  <img
                    className={classes.menuFlag}
                    src={language_mapping[item?.language]}
                    alt={t(`menu_language_${item?.language}`)}
                  />
                ) : null}
                <IconButton disabled={newItem} style={{ paddingRight: '17px' }}>
                  {!openLanguageMenu ? <ArrowDropDown /> : <ArrowDropUp />}
                </IconButton>
              </>
            }
            textValue={
              item?.language
                ? t('menu_language_' + item?.language)
                : t('preferred_language')
            }
            inputLabel={t('menu_language')}
            onClick={handleOpenLanguageMenu}
            disabled={newItem}
            required
          />
          <Menu
            disabled={newItem}
            variant="menu"
            open={Boolean(openLanguageMenu)}
            anchorEl={openLanguageMenu}
            getContentAnchorEl={null}
            disableAutoFocusItem
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => {
              setOpenLanguageMenu(false);
            }}
            PaperProps={{
              style: {
                maxHeight: '50%',
              },
            }}
          >
            {Object.keys(language_mapping).map((lang) => (
              <MenuItem
                key={lang}
                value={lang}
                onClick={(e) => {
                  setOpenLanguageMenu(false);
                  onChange(e, 'language', lang);
                }}
              >
                <img
                  className={classes.flag}
                  src={language_mapping[lang]}
                  alt={t(`menu_language_${lang}`)}
                />
              </MenuItem>
            ))}
          </Menu>
        </>

        {!newItem ? (
          <>
            <VmsDialogRowSelectArray
              rowClass={classes.dialogRow2}
              iconLeft={<School />}
              selectTypes={[
                { key: t('unselect'), value: t('unselect') },
                ...tenants,
              ]}
              selectedType={item?.copyTenant}
              mapOfItem={(item) => {
                return item.key;
              }}
              placeholder={t('settings_company_copy_tenant')}
              disabled={newItem}
              inputName="copyTenant"
              onChange={(e) => {
                handleChangeCopyTenant(e);
              }}
            />
            <VmsDialogFooter
              saveDisabled={!disabledSave}
              onSave={() => {
                onSave();
              }}
            />
          </>
        ) : null}
      </Paper>
    </Modal>
  );
};

TenantDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TenantDialog);
