import AbstractService from 'services/AbstractService';
import Auth from '../modules/Auth';

class TenantServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'tenants/';
  }

  /**
   * Get all users
   */
  getTenants() {
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(this.endpoint, config);
  }
  getTenant(tenantId) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(this.endpoint + tenantId, config);
  }

  createTenant(data) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(this.endpoint + 'newTenant', data, config);
  }

  deactivateTenant(tenantId) {
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(
      this.endpoint + 'deactivate',
      { tenantId: tenantId },
      config
    );
  }
}

const TenantService = new TenantServiceImpl();

export default TenantService;
